@import "@styles/theme";

.root {
  width: fit-content;
  font-weight: 500;
  &.strong {
    font-weight: 600;
  }
  padding: 1rem 4.5rem;
  margin: 0.5rem 0;

  text-align: center;
  font-family: $font-family-body;
  text-decoration: none;
  background-color: $color-primary;
  color: $color-white;
  &.outlined {
    background-color: $color-white;
    color: $color-primary;
    border: 1px solid $color-primary;
  }
}

.compromiso {
  padding: 1rem 4.2rem;
}
.compromisoEm {
  padding: 1rem 3rem;
}
