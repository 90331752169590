@import "@styles/theme";

$weights: (
  100: 100,
  200: 200,
  300: 300,
  400: 400,
  500: 500,
  600: 600,
  700: 700,
  800: 800,
  900: 900,
);

$sizes: (
  10: 1rem,
  12: 1.2rem,
  14: 1.4rem,
  16: 1.6rem,
  20: 2rem,
  24: 2.4rem,
  28: 2.8rem,
  34: 3.4rem,
  36: 3.6rem,
  40: 4rem,
  48: 4.8rem,
);

$line-height-factor: (
  10: 1.2rem,
  12: 1.6rem,
  14: 2rem,
  16: 2.2rem,
  20: 2.6rem,
  24: 3.2rem,
  28: 3.6rem,
  34: 4rem,
  36: 4.2rem,
  40: 4.6rem,
  48: 5.4rem,
);

.t {
  font-family: "Open Sans", sans-serif;
}

.t-not-select {
  @include makeNotSelectable;
}
.t-transform {
  &--uppercase {
    text-transform: uppercase;
  }
}

.t-truncate {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.t-align {
  &--center {
    text-align: center;
  }

  &--left {
    text-align: left;
  }

  &--right {
    text-align: right;
  }
}

@mixin make-weights {
  @each $key, $value in $weights {
    .t-weight--#{$key} {
      font-weight: $value;
    }
  }
}
@mixin make-sizes {
  @each $key, $value in $sizes {
    .t-size--#{$key} {
      font-size: $value;
      line-height: map-get($map: $line-height-factor, $key: $value);
    }
  }
}

@include make-weights();
@include make-sizes();
