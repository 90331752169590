@import "@styles/theme";

.strong {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.6rem;
}

.normal {
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.6rem;
}

.small {
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2.4rem;
}

.micro {
  font-weight: 600;
  font-size: 1rem;
  line-height: $font-size-base;
  letter-spacing: 1px;
}

.merry {
  font-family: $font-family-headings;
  font-size: 1.4rem;
  line-height: 2.2rem;
}
