@import '@styles/theme';

.root {
  font-family: $font-family-body;
  overflow-wrap: break-word;
  @include marginHorizontal(32);
  padding-bottom: 32px;
  width: 90%;
  max-width: 920px;
  margin: 0 auto;
  p {
    margin: 5px 0;
  }
  ul {
    li {
      margin-bottom: 10px;
    }
  }
  table {
    @include marginVertical(15);
    border-collapse: collapse;
    border-spacing: 10px; // Espaciado entre filas y columnas

    td {
      border: 1px solid black;
      @include paddingHorizontal(12); // Más espacio dentro de las celdas
      @include paddingVertical(8);
    }
  }
  h3 {
    margin-top: 20px;
  }
  h4 {
    margin-top: 20px;
  }
  ul {
    margin-left: 25px;
  }
  .slots {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.root_error {
  background-color: $color-primary;
  flex-grow: 1;
  @include paddingHorizontal(32);
  @include column();
  min-height: 100vh;
  height: 100%;
  justify-content: center;
  align-items: center;
  svg {
    margin-bottom: 16px;
    @include for-size(mobile-portrait) {
      width: 100px;
      height: auto;
    }
  }
  h2 {
    text-align: center;
    color: $color-white;
    @include marginVertical(6);
  }
  p {
    color: $color-white;
    text-align: center;
  }
  a {
    @include paddingHorizontal(16);
    @include paddingVertical(12);
    @include marginVertical(16);
    color: $color-black;
    background-color: $color-white;
    text-decoration: none;
    p {
      color: $color-black;
      letter-spacing: 1px;
    }
  }
}
